import { FunctionComponent } from "react";
import { OneClickSubscribeModule } from "@bluebottlecoffee/design-system/components";
import { useCart } from "@chordcommerce/react-autonomy";
import {
  DereferencedOneClickSubscribeModuleProps,
  toOneClickSubscribeModuleProps,
} from "../lib/transformers/one-click-subscribe-module";

export type OneClickSubscribeModuleWrapperProps = {
  moduleProps: DereferencedOneClickSubscribeModuleProps;
  lang: string;
};

export const OneClickSubscribeModuleWrapper: FunctionComponent<
  OneClickSubscribeModuleWrapperProps
> = ({ moduleProps, lang }) => {
  const { addSubscription } = useCart();

  const transformedProps = toOneClickSubscribeModuleProps(
    moduleProps,
    lang,
    addSubscription,
  );

  return <OneClickSubscribeModule {...transformedProps} />;
};
