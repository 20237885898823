import {
  TwoUpProps,
  TwoUpComponentType,
} from "@bluebottlecoffee/design-system/components";
import { ElementOf } from "../utils";
import { Carousel, TwoUp as TwoUpSchema } from "../sanity-schema";
import { toImageResponsiveProps } from "./image";
import { toCopyColumnProps } from "./copy-column";
import {
  FlavorProfileCopyTranslations,
  toFlavorProfileProps,
} from "./flavor-profile";
import { toQuoteProps } from "./quote";
import { toVimeoPlayerProps } from "./vimeo-player";
import { toBulletedKeyValueListProps } from "./bulleted-key-value-list";

function transform(
  module: ElementOf<TwoUpSchema["components"]>,
  bgColor: TwoUpSchema["bgColor"],
  region: string,
  lang: string,
  flavorProfileCopy?: FlavorProfileCopyTranslations,
): TwoUpComponentType {
  switch (module._type) {
    case "imageResponsive":
      return {
        _type: "imageResponsive",
        ...toImageResponsiveProps(module, lang),
      };
    case "bulletedKeyValueList":
      return {
        _type: "bulletedKeyValueList",
        ...toBulletedKeyValueListProps(module, bgColor, region, lang),
      };
    case "carousel": {
      const m: Carousel = module;
      return {
        _type: "carousel",
        ...{ images: m.images.map((i) => toImageResponsiveProps(i, lang)) },
      };
    }
    case "copyColumn":
      return {
        _type: "copyColumn",
        ...toCopyColumnProps(module, region, lang),
      };
    case "flavorProfile":
      return {
        _type: "flavorProfile",
        ...toFlavorProfileProps(module, lang, flavorProfileCopy, bgColor),
      };
    case "quote":
      return {
        _type: "quote",
        ...toQuoteProps(module, lang),
      };
    case "vimeoPlayer":
      return {
        _type: "vimeoPlayer",
        ...toVimeoPlayerProps(module),
      };
    default: {
      throw Error(`Two up transform failed`);
    }
  }
}

export function toTwoUpProps(
  data: TwoUpSchema,
  region: string,
  lang: string,
  copy?: FlavorProfileCopyTranslations,
): TwoUpProps {
  return {
    bgColor: data.bgColor,
    components: [
      transform(data.components[0], data.bgColor, region, lang, copy),
      transform(data.components[1], data.bgColor, region, lang, copy),
    ],
    mobileRenderingOrder:
      data.mobileRenderingOrder === "reverse" ? "reverseColumn" : "column",
  };
}
