import {
  ConversionCopy,
  QuickShopCarouselProps,
} from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";

import { ShopCardPropsWithSlug, toShopCardProps } from "./shop-card";
import { ShippingCopy } from "../sanity-schema";
import { DereferencedQuickShopCarouselProps } from "../../components/QuickShopCarouselWrapper";

export type QuickShopCarouselTransformerProps = Omit<
  QuickShopCarouselProps,
  "shopCards"
> & {
  shopCards: ShopCardPropsWithSlug[];
};

export function toQuickShopCarouselProps(
  copy: ConversionCopy & ShippingCopy,
  data: DereferencedQuickShopCarouselProps,
  lang: string,
  region: string,
): QuickShopCarouselTransformerProps {
  const { shopCards: products } = data;
  const shopCardsForSale = products.filter((product) => product.forSale);
  if (shopCardsForSale.length < 3) {
    throw Error(
      "QuickShopCarousel must have at least 3 products that are for sale.",
    );
  }

  const shopCards = shopCardsForSale.map((shopCard) => ({
    ...toShopCardProps({
      data: shopCard,
      lang,
      region,
      copy,
      shippingCopy: copy,
    }),
  }));

  return {
    bgColor: data.bgColor ?? undefined,
    heading: toHTML(data.heading[lang]),
    shopCards,
  };
}
