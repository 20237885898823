import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export const useExperiment = (campaignId: string) => {
  const [variant, setVariant] = useState<string>("1");

  useEffect(() => {
    (async () => {
      // We don't know when cookie of the test will be available, we should use setInterval to read the variant when the experiment it's available.

      let intervalId: ReturnType<typeof setInterval>;

      const timeoutId = setTimeout(() => {
        clearInterval(intervalId);
      }, 5000);

      intervalId = setInterval(() => {
        const variantVisId = Cookies.get(`_vis_opt_exp_${campaignId}_combi`);
        const variantDebugId = Cookies.get(
          `debug_vis_opt_exp_${campaignId}_combi`,
        );

        const variantId = variantVisId || variantDebugId;

        if (variantId) {
          setVariant(variantId);
          console.log(
            `Campaing Id Running: ${campaignId}, Variant: ${variantId}`,
          );
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        }
      }, 100);
    })();
  }, []);

  return Number(variant);
};
